html,
body {
    min-height: 100vh;
    height: auto;
    margin: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
